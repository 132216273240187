import { GatsbyImage } from 'components/Common/GatsbyImage';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { grid, space, layout, typography } from 'styled-utils';
import { heading4Style } from 'components/Common/Text';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${space({ px: [20, 0], py: [32, 40] })}
`;

export const MainImage = styled(GatsbyImage)`
  ${layout({ width: ['100%', 900] })}
  ${space({ mb: [32, 40] })}
`;

export const Title = styled.h1`
  margin-bottom: 24px;
  max-width: 600px;
`;

export const Text = styled.div`
  margin-bottom: 16px;
  ${layout({ maxWidth: ['100%', 600] })}
`;

export const TitleOther = styled.h4`
  ${heading4Style}
  ${space({ mb: [26, 42] })}
`;

export const Subarticles = styled.div`
  display: grid;
  ${grid({ gridTemplateColumns: ['1fr', '1fr 1fr'] })}
  grid-gap: 20px;
`;

export const Subarticle = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${layout({ width: ['100%', 355] })}
`;

export const SubarticleImage = styled(GatsbyImage)`
  width: 120px;
  height: 120px;
  margin-right: 10px;
`;

export const SubarticleText = styled.div`
  flex: 1;
  text-decoration: none;
  ${({ theme }) =>
    typography({
      ...theme.typography.default,
      fontWeight: theme.fontWeights.bold,
    })}
  color: ${({ theme }) => theme.colors.black};
`;
