import React from 'react';
import { graphql } from 'gatsby';
import {
  LadderArticlePageBySlugQuery,
  SanityLadderArticlePage,
} from 'graphql-types';

import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { LadderApp } from 'templates/ladder/LadderApp';
import { ArticlePageContent } from 'components/ArticlePageContent/ArticlePageContent';

export const LearnPageTemplate: React.FC<{
  data: LadderArticlePageBySlugQuery;
}> = ({ data }) => {
  const { slug, seoMetadata } = data.page || {};

  useLogPageViewed(undefined, { type: 'Learn' });
  useTagStandardPageView('page_view');

  return (
    <LadderApp>
      {seoMetadata && slug?.current && (
        <SeoMetadata metaData={seoMetadata} slug={`pages/${slug.current}`} />
      )}
      <ArticlePageContent page={data.page as SanityLadderArticlePage} />
    </LadderApp>
  );
};

export default LearnPageTemplate;

export const query = graphql`
  query LadderArticlePageBySlug($slug: String!) {
    page: sanityLadderArticlePage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      image {
        image {
          asset {
            gatsbyImageData(width: 900, placeholder: NONE)
          }
        }
        alt
      }
      text {
        ...SanityStandardRichTextWithInserts
      }
      previewText
      otherArticles {
        name
        slug {
          current
        }
        image {
          image {
            asset {
              gatsbyImageData(width: 120, placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  }
`;
