import {
  SanityLadderArticlePage,
  StandardRichTextFragment,
} from 'graphql-types';
import React, { FC } from 'react';

import { useIsMobile } from 'hooks/use-media-query';
import { PDPPageContainer } from 'components/Pdp';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';
import {
  Container,
  MainImage,
  Title,
  Text,
  TitleOther,
  Subarticles,
  Subarticle,
  SubarticleImage,
  SubarticleText,
} from './ArticlePageContent.styles';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

export const ArticlePageContent: FC<{ page: SanityLadderArticlePage }> = ({
  page,
}) => {
  const isMobile = useIsMobile();
  const mainImage = getSimplifiedImageData(page.image?.image);

  return (
    <PDPPageContainer>
      <Container>
        {isMobile && <Title>{page.name}</Title>}
        {mainImage ? (
          <ImageLoadingWrapper eager>
            <MainImage
              alt={page.image?.alt || ''}
              image={mainImage}
              imgStyle={{ objectFit: 'cover' }}
            />
          </ImageLoadingWrapper>
        ) : null}
        {!isMobile && <Title>{page.name}</Title>}

        <Text>
          <StandardRichText
            fields={page.text as StandardRichTextFragment}
            maxImageWidth={600}
          />
        </Text>

        {page.otherArticles?.length && (
          <>
            <TitleOther>NOW CHECK THESE OUT</TitleOther>
            <Subarticles>
              {page.otherArticles.map((subarticle, index) => {
                const subArticleImage = getSimplifiedImageData(
                  subarticle?.image?.image
                );

                return (
                  <Subarticle
                    key={index}
                    to={`/pages/${subarticle?.slug?.current}`}
                  >
                    {subArticleImage ? (
                      <SubarticleImage
                        alt={subarticle?.image?.alt || ''}
                        image={subArticleImage}
                        imgStyle={{ objectFit: 'cover' }}
                      />
                    ) : null}
                    <SubarticleText>{subarticle?.name}</SubarticleText>
                  </Subarticle>
                );
              })}
            </Subarticles>
          </>
        )}
      </Container>
    </PDPPageContainer>
  );
};
